export const MUTATIONS = {
  USER_DETAILS_UPDATE: 'USER_DETAILS_UPDATE',
  USER_PERSONAL_DETAILS_UPDATE: 'USER_PERSONAL_DETAILS_UPDATE',
  USER_TYPE_SELECT: 'USER_TYPE_SELECT',
  LOCAL_LOGIN_RETRIEVE: 'LOCAL_LOGIN_RETRIEVE',
  OCR_DATA_UPDATE: 'OCR_DATA_UPDATE',
  LEGAL_PERSON_REPRESENTATIVE_SELECT: 'LEGAL_PERSON_REPRESENTATIVE_SELECT',
  LEGAL_PERSON_DOCUMENT_VERIFICATION: 'LEGAL_PERSON_DOCUMENT_VERIFICATION',
  LEGAL_PERSON_ADDRESS_UPDATE: 'LEGAL_PERSON_ADDRESS_UPDATE',
  CANCEL_PROCESS: 'CANCEL_PROCESS',
  REDIRECT_URL_RETRIEVE: 'REDIRECT_URL_RETRIEVE',
  SIGNIN_SERVICE_PROCESS_ID_GET: 'SIGNIN_SERVICE_PROCESS_ID_GET',
  STATUSES_GET: 'STATUSES_GET',
  UNDO_PROCESS_STEP: 'UNDO_PROCESS_STEP',
  SERVICE_PROVIDER_ID_GET: 'SERVICE_PROVIDER_ID_GET',
  SERVICE_PROVIDER_DATA_GET: 'SERVICE_PROVIDER_DATA_GET',
  WAAS_PAR: 'WAAS_PAR',
  WAAS_REGISTER_USER_DETAILS: 'WAAS_REGISTER_USER_DETAILS',
  WAAS_CODE_CHALLENGE: 'WAAS_CODE_CHALLENGE',
  SCAN_EVENTS: 'SCAN_EVENTS',
  VERIFY_CANCEL_PROCESS: 'VERIFY_CANCEL_PROCESS',
  VERIFY_REDIRECT_URL: 'VERIFY_REDIRECT_URL',
  VERIFY_UPLOAD_DOCUMENT: 'VERIFY_UPLOAD_DOCUMENT',
  VERIFY_FINISH_DOCUMENT_VERIFICATION: 'VERIFY_FINISH_DOCUMENT_VERIFICATION',
};
